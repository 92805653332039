import {
	CreditLineContractListData,
	FinancingAccountV2ResponseCoreBankingEnum,
	GetAccountAgreementData,
	GetContractData,
} from '../../../types/generated/pos';
import { BASE_URL } from '@utils/common';
import {
	ResponseFormat,
	useFetch,
} from '@api/services/utilsService/utilsApiService';

export const useGetCreditAgreementApi = () => {
	return useFetch<CreditLineContractListData>(
		`${BASE_URL}/v1/credit-line/contract`
	);
};

export const useGetLoanContract = (id: string) => {
	return useFetch<GetContractData>(`${BASE_URL}/v1/loan/${id}/contract`);
};

export const useGetLoanAgreement = (id: string) => {
	return useFetch<GetAccountAgreementData>(
		`${BASE_URL}/v1/accounts/${id}/agreement`
	);
};

export const useGetLoanAgreementApi = (
	id: string,
	coreBanking: FinancingAccountV2ResponseCoreBankingEnum
) => {
	const apiUrls: {
		[key in FinancingAccountV2ResponseCoreBankingEnum]: {
			url: string;
			responseFormat: ResponseFormat;
		};
	} = {
		[FinancingAccountV2ResponseCoreBankingEnum.IF]: {
			url: `${BASE_URL}/v1/accounts/${id}/agreement`,
			responseFormat: ResponseFormat.JSON,
		},
		[FinancingAccountV2ResponseCoreBankingEnum.AXI]: {
			url: `${BASE_URL}/v1/loan/${id}/contract`,
			responseFormat: ResponseFormat.BLOB,
		},
	};

	return useFetch<GetAccountAgreementData | GetContractData>(
		apiUrls[coreBanking].url,
		undefined,
		undefined,
		apiUrls[coreBanking].responseFormat
	);
};
