import {
	AccountsV2Response,
	CreditLineAccountV2Response,
	DebitAccountV2Response,
	FinancingAccountV2Response,
	SavingsAccountV2Response,
	Warnings,
} from '../../types/generated/pos';
import { DataWithState } from '@api/models/common';
import { AccountType } from '../../types/generated/operations';

export type MappedAccountObject = {
	[AccountType.FINANCING]: FinancingAccountV2Response[];
	[AccountType.CREDIT_LINE]: CreditLineAccountV2Response[];
	[AccountType.DEBIT]: DebitAccountV2Response[];
	[AccountType.SAVINGS]: SavingsAccountV2Response[];
};

export type MappedAccountResponse = {
	accounts: MappedAccountObject | null;
	warnings?: Warnings;
};

export const accountMapper = (
	dataWithState: DataWithState<AccountsV2Response>
): DataWithState<MappedAccountResponse> => {
	const { data, loading, error } = dataWithState;

	const initialState = {
		[AccountType.FINANCING]: [],
		[AccountType.CREDIT_LINE]: [],
		[AccountType.DEBIT]: [],
		[AccountType.SAVINGS]: [],
	};

	const groupedAccounts = data?.accounts?.reduce((acc, account) => {
		const { type } = account;
		if (!acc[type]) {
			acc[type] = [];
		}
		acc[type].push(account as never);
		return acc;
	}, initialState as MappedAccountObject);

	const mappedData: MappedAccountResponse = {
		accounts: groupedAccounts ?? null,
		warnings: data?.warnings,
	};

	return {
		error,
		loading,
		data: mappedData,
	};
};
