import { getAccountById } from './getAccountById';
import {
	FinancingAccountV2Response,
	FinancingAccountV2ResponseLoanTypeEnum,
} from 'src/types/generated/pos';
import { AccountParams } from '../QrRepayment';

export const getAccountName = (
	params: AccountParams
): 'Salmon Credit' | 'Financing' | 'Cash Loan' => {
	const account = getAccountById(params);

	if (
		(account as FinancingAccountV2Response).loanType ===
		FinancingAccountV2ResponseLoanTypeEnum.CASH
	) {
		return 'Cash Loan';
	} else if (
		(account as FinancingAccountV2Response).loanType ===
		FinancingAccountV2ResponseLoanTypeEnum.POS
	) {
		return 'Financing';
	} else {
		return 'Salmon Credit';
	}
};
