import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { NotFound } from '../NotFound/NotFound';
import AccountList from './AccountList/AccountList';
import { DataWithState } from '@api/models/common';
import { CreditLineStateResponse } from '../../types/generated/pos';
import { useRedirectToAccount } from './utils/useRedirectToAccount';
import { MappedAccountResponse } from '@api/mappers/accountMapper';

interface MainPageProps {
	accountsState: DataWithState<MappedAccountResponse>;
	creditStatusState: DataWithState<CreditLineStateResponse>;
}

function MainPage(props: MainPageProps) {
	const { accountsState, creditStatusState } = props;

	useRedirectToAccount(accountsState);

	if (accountsState.loading) {
		return <LoadingScreen />;
	}

	if (accountsState?.data?.accounts) {
		return (
			<AccountList
				accounts={accountsState?.data?.accounts}
				creditStatus={creditStatusState.data}
			/>
		);
	}

	return <NotFound />;
}

export default MainPage;
