import {
	CreditLineAccountV2Response,
	CreditLineStateResponse,
	FinancingAccountV2Response,
} from '../../../types/generated/pos';
import { getCreditMinPayment } from './getCreditMinPayment';

export type AccountEventOptions = {
	loanNumber: string;
	loanType: 'CASH' | 'POS' | 'CreditLine';
	nextPayment_date?: Required<FinancingAccountV2Response>['state']['dueDate'];
	nextPayment_amount?: Required<FinancingAccountV2Response>['state']['amount'];
};

export const getLoanEventOptions = (
	loan: FinancingAccountV2Response
): AccountEventOptions => {
	return {
		loanNumber: loan.id,
		loanType: loan.loanType,
		nextPayment_date: loan.state?.dueDate,
		nextPayment_amount: loan.state?.amount,
	};
};

export const getCreditEventOptions = (
	credit: CreditLineAccountV2Response,
	creditStatus?: CreditLineStateResponse | null
): AccountEventOptions => {
	return {
		loanNumber: credit.id,
		loanType: 'CreditLine',
		nextPayment_date: credit?.dueDate,
		nextPayment_amount: creditStatus
			? getCreditMinPayment(creditStatus)
			: undefined,
	};
};
