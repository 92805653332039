import { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { track } from '@amplitude/analytics-browser';

import CreditItem, { AccountItemProps } from './AccountItem/AccountItem';
import { AccountStatuses, LoanTypes } from '@api/models/account';
import {
	CreditLineStateResponse,
	FinancingAccountV2ResponseStatusEnum,
} from '../../../types/generated/pos';
import { parseDate } from '@utils/date';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { formatMoney } from '@utils/formatMoney/formatMoney';
import { creditLineRoute, financingRoute } from '@config/routes';
import {
	getCreditEventOptions,
	getLoanEventOptions,
} from '../../Accounts/utils/eventOptions';
import { getShortDate } from '@utils/dates/formatDates';

import Cart from '@assets/icons/Cart.svg';
import Cash from '@assets/icons/Cash.svg';
import CreditItemIcon from '@assets/icons/CreditItem.svg';
import Check from '@assets/icons/Check.svg';
import { MappedAccountObject } from '@api/mappers/accountMapper';
import { AccountType } from '../../../types/generated/operations';

const MainCaption = styled.div`
	font-weight: 800;
	font-size: 20px;
	line-height: 24px;
	color: rgba(27, 31, 59, 0.8);
	margin: 16px 0;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

const EmptyMessage = styled.p`
	text-align: center;
`;

export const LoanTypeToCaption = {
	[LoanTypes.POS]: 'Financing',
	[LoanTypes.CASH]: 'Cash Loan',
};

const LoanTypeAndStatusToIcon = {
	[LoanTypes.POS]: Cart,
	[LoanTypes.CASH]: Cash,
	[AccountStatuses.CLOSED]: Check,
};

type AccountListProps = {
	accounts: MappedAccountObject;
	creditStatus?: CreditLineStateResponse | null;
};

const getAccountItems = (props: AccountListProps): AccountItemProps[] => {
	const { creditStatus, accounts } = props;
	const loanItems = accounts.FINANCING?.map((loan): AccountItemProps => {
		const isClosed =
			loan.status === FinancingAccountV2ResponseStatusEnum.CLOSED;

		const title = LoanTypeToCaption[loan.loanType] || 'Loan';

		const amountText = isClosed
			? 'Congratulations!'
			: formatMoney(loan.state?.amount || 0);

		const parsedDueDate = loan?.state && parseDate(loan?.state?.dueDate);
		const caption = isClosed
			? 'Your financial product is fully paid'
			: `is due on ${parsedDueDate ? getShortDate(parsedDueDate) : ''}`;

		const iconSrc = isClosed
			? LoanTypeAndStatusToIcon[AccountStatuses.CLOSED]
			: LoanTypeAndStatusToIcon[loan.loanType];

		const link = financingRoute(loan.id);

		const eventOptions = getLoanEventOptions(loan);
		const onClick = () => {
			track(AmplitudeEvents.Product_Tap, { product: eventOptions });
		};

		return { title, amountText, caption, iconSrc, isClosed, link, onClick };
	});

	const creditLine = accounts[AccountType.CREDIT_LINE]?.at(0);

	const eventOptions = creditLine
		? getCreditEventOptions(creditLine, creditStatus)
		: undefined;

	const creditItem: AccountItemProps | undefined = creditLine
		? {
				title: 'Salmon Credit',
				amountText: formatMoney(creditLine?.balance.available),
				caption: 'Available balance',
				iconSrc: CreditItemIcon,
				isClosed: false,
				link: creditLineRoute(creditLine?.id),
				onClick: () => {
					track(AmplitudeEvents.Product_Tap, { product: eventOptions });
				},
		  }
		: undefined;

	return [creditItem, ...loanItems].filter(
		(accountItem): accountItem is AccountItemProps => !!accountItem
	);
};

const AccountList = (props: AccountListProps) => {
	const accountItems = useMemo(() => getAccountItems(props), [props]);
	const creditLine = props?.accounts[AccountType.CREDIT_LINE]?.at(0);

	useEffect(() => {
		const creditEventOptions = creditLine
			? getCreditEventOptions(creditLine, props.creditStatus)
			: undefined;

		const loanEventOptionsList =
			props.accounts.FINANCING?.map(getLoanEventOptions);

		track(AmplitudeEvents.Home_Opened, {
			products: [...loanEventOptionsList, creditEventOptions].filter(Boolean),
		});
	}, []);

	if (!accountItems.length) {
		return (
			<div>
				<EmptyMessage>There is no products yet</EmptyMessage>
			</div>
		);
	}

	return (
		<div>
			<MainCaption>Your products</MainCaption>
			<Container>
				{accountItems?.map((account) => (
					<CreditItem key={account.link} {...account} />
				))}
			</Container>
		</div>
	);
};

export default AccountList;
