import { MoneyCurrencyEnum } from '../../types/generated/pos';

const getPeso = (sentimo: number): number => {
	return sentimo / 100;
};

// 123400 => ₱1,234.00
export const formatSentimo = (sentimo: number): string => {
	const peso = getPeso(sentimo);

	return peso.toLocaleString('en-US', {
		minimumFractionDigits: 2,
		style: 'currency',
		currency: MoneyCurrencyEnum.PHP,
	});
};
