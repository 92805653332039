import { ErrorBoundary } from 'react-error-boundary';

import QrRepayment from './QrRepayment';
import ErrorBlock from './QrRepaymentError';
import QrRepaymentLoad from './QrRepaymentLoad';
import { DataWithState } from '@api/models/common';
import { useEnsuredMoney, useEnsuredParams } from '@utils/useEnsuredParams';
import { QrRepaymentParams } from '@config/routes';
import { MappedAccountResponse } from '@api/mappers/accountMapper';

interface QrRepaymentPageProps {
	accountsState: DataWithState<MappedAccountResponse>;
	userName: string;
}

const QrRepaymentPage = (props: QrRepaymentPageProps) => {
	const { accountsState, userName } = props;
	const accounts = accountsState.data?.accounts;

	const amount = useEnsuredMoney(QrRepaymentParams.amount);
	const providerId = useEnsuredParams(QrRepaymentParams.providerId);

	if (!accounts) {
		return <QrRepaymentLoad amount={amount} />;
	}

	return (
		<QrRepayment
			amount={amount}
			providerId={providerId}
			userName={userName}
			accounts={accounts}
		/>
	);
};

const QrRepaymentPageWithError: typeof QrRepaymentPage = (props) => {
	return (
		<ErrorBoundary FallbackComponent={ErrorBlock}>
			<QrRepaymentPage {...props} />
		</ErrorBoundary>
	);
};

export default QrRepaymentPageWithError;
