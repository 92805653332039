import { FC } from 'react';
import styled from '@emotion/styled';
import {
	Money,
	OperationDetails,
	OperationDirection,
	OperationStatus,
	OperationType,
} from '../../../types/generated/operations';
import { formatMoney } from '@utils/formatMoney/formatMoney';
import { getTime } from '@utils/dates/formatDates';
import { CdnIcon } from '@fhl-world/salmon-ui';

import Union from '@assets/icons/Union.svg';
import Time from '@assets/icons/Time.svg';
import { capitalizeFirstLetter } from '@utils/capitalizeFirstLetter/capitalizeFirstLetter';
import Repayment from '@assets/icons/Repayment.svg';

const RowItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 18px 0;
`;

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
	position: relative;
`;

const Icon = styled.img`
	width: 16px;
	height: 16px;
	position: absolute;
	bottom: -20%;
	right: -20%;
	border-radius: 50%;
	border: 2px solid #fff;
`;

const RowInner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ItemName = styled.span`
	font-size: 15px;
	line-height: 24px;
	font-weight: 800;
`;

const ItemDate = styled.span`
	font-size: 13px;
	line-height: 20px;
	font-weight: 500;
	display: block;
	text-align: right;
`;

const ItemType = styled.span`
	font-size: 13px;
	line-height: 20px;
	font-weight: 500;
	display: block;
`;

const CompletedInner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	color: #34b41f;
	justify-self: flex-end;
`;

const FailedInner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	color: #dd4c1e;
	justify-self: flex-end;
`;

const StatusIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 6px;
`;

const PendingInner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
`;

const Transfer = ({ amount }: AmountViewProps) => (
	<FailedInner>–{formatMoney(amount)}</FailedInner>
);

const PaymentCompleted = ({ amount }: AmountViewProps) => (
	<CompletedInner>+{formatMoney(amount)}</CompletedInner>
);

const PaymentFailed = ({ amount }: AmountViewProps) => (
	<FailedInner>
		+{formatMoney(amount)}
		<StatusIconWrapper>
			<img src={Union} alt="logo" width={16} height={16} />
		</StatusIconWrapper>
	</FailedInner>
);

const PaymentPending = ({ amount }: AmountViewProps) => (
	<PendingInner>
		+{formatMoney(amount)}
		<StatusIconWrapper>
			<img src={Time} alt="logo" width={16} height={16} />
		</StatusIconWrapper>
	</PendingInner>
);

type AmountViewProps = { amount: Money };
const AmountViewComponents: Record<
	OperationDirection,
	Record<OperationStatus, FC<AmountViewProps>>
> = {
	[OperationDirection.OUT]: {
		[OperationStatus.SUCCESSFUL]: Transfer,
		[OperationStatus.FAILED]: Transfer,
		[OperationStatus.PENDING]: Transfer,
	},
	[OperationDirection.IN]: {
		[OperationStatus.SUCCESSFUL]: PaymentCompleted,
		[OperationStatus.FAILED]: PaymentFailed,
		[OperationStatus.PENDING]: PaymentPending,
	},
};

export type HistoryItemProps = {
	id: string;
	direction?: OperationDirection;
	iconCode: string;
	providerName: string;
	timestamp: Date;
	amount: Money;
	status: OperationStatus;
	type: OperationType;
	details?: OperationDetails;
};

const HistoryItem = (props: HistoryItemProps) => {
	const {
		direction,
		iconCode,
		providerName,
		timestamp,
		amount,
		status,
		type,
		details,
	} = props;

	let AmountViewComponent: FC<AmountViewProps> | undefined;

	if (direction) {
		AmountViewComponent = AmountViewComponents[direction][status];
	}

	const isAutoDebt = (data?: OperationDetails): boolean => {
		if (!data) return false;

		if ('provider' in data) {
			return !!data.provider.isAutoPayment;
		} else {
			return false;
		}
	};

	const formatPaymentType = (operationType: OperationType): string => {
		const initialFormatedType = operationType.toLowerCase()?.replace('_', ' ');
		if (/qr/gi.test(initialFormatedType)) {
			const [left, right] = initialFormatedType.split(' ');
			return `${left.toUpperCase()} ${right}`;
		}
		return capitalizeFirstLetter(initialFormatedType);
	};

	return (
		<RowItem>
			<RowInner>
				<IconWrapper>
					<CdnIcon code={iconCode} width={40} height={40} />
					{isAutoDebt(details) && <Icon src={Repayment} alt="Repayment icon" />}
				</IconWrapper>
				<div>
					<ItemName>{providerName}</ItemName>
					<ItemType>{formatPaymentType(type)}</ItemType>
				</div>
			</RowInner>
			<RowInner>
				<div>
					{AmountViewComponent && <AmountViewComponent amount={amount} />}
					<ItemDate>{getTime(timestamp)}</ItemDate>
				</div>
			</RowInner>
		</RowItem>
	);
};

export default HistoryItem;
