import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import {
	FinancingAccountV2Response,
	FinancingAccountV2ResponseLoanTypeEnum,
	Money,
} from 'src/types/generated/pos';
import { getAccountById } from './getAccountById';

type Params = {
	amount: Money;
} & Parameters<typeof getAccountById>[0];

const getProductName = (params: Params): string => {
	const account = getAccountById(params);

	if (
		(account as FinancingAccountV2Response).loanType ===
		FinancingAccountV2ResponseLoanTypeEnum.CASH
	) {
		return 'Cashloan';
	} else if (
		(account as FinancingAccountV2Response).loanType ===
		FinancingAccountV2ResponseLoanTypeEnum.POS
	) {
		return 'POS';
	} else {
		return 'Creditline';
	}
};

export const trackOpen = (params: Params) => {
	track(AmplitudeEvents.QRPh_Opened, {
		product: getProductName(params),
		amount: params.amount.amount,
	});
};

export const trackDownload = () =>
	track(AmplitudeEvents.Download_Tap, { source: 'QR' });
