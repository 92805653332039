import Success from '@assets/icons/Success.svg';
import { LoanTypeToCaption } from '../../MainPage/AccountList/AccountList';
import { FinancingAccountV2ResponseLoanTypeEnum } from '../../../types/generated/pos';
import styled from '@emotion/styled';

interface LoanClosedStatusProps {
	loanType: FinancingAccountV2ResponseLoanTypeEnum;
}

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Caption = styled.div`
	font-size: 17px;
	line-height: 28px;
	font-weight: 800;
`;

const SuccessMessage = styled.span`
	font-size: 15px;
	line-height: 24px;
	font-weight: 500;
	padding: 12px 0;
`;

function ClosedAccount({ loanType }: LoanClosedStatusProps) {
	return (
		<Container>
			<div>
				<Caption>{LoanTypeToCaption[loanType]} product closed</Caption>
				<SuccessMessage>You made it!</SuccessMessage>
			</div>
			<div>
				<img src={Success} alt="logo" />
			</div>
		</Container>
	);
}

export default ClosedAccount;
