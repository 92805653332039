import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataWithState } from '@api/models/common';
import { creditLineRoute, financingRoute } from '@config/routes';
import { MappedAccountResponse } from '@api/mappers/accountMapper';

export const useRedirectToAccount = (
	accountsState: DataWithState<MappedAccountResponse>
): void => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!accountsState.data) {
			return;
		}

		if (
			accountsState.data.accounts?.CREDIT_LINE?.length === 1 &&
			!accountsState.data.accounts.FINANCING?.length
		) {
			const onlyOneCredit = accountsState.data.accounts.CREDIT_LINE[0];
			navigate(creditLineRoute(onlyOneCredit.id));
		}

		if (
			!accountsState.data.accounts?.CREDIT_LINE?.length &&
			accountsState.data.accounts?.FINANCING.length === 1
		) {
			const onlyOneLoan = accountsState.data.accounts?.FINANCING[0];
			navigate(financingRoute(onlyOneLoan?.id));
		}
	}, [accountsState.data]);
};
