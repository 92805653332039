import { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { parseISO } from 'date-fns';
import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { useGetOperationsApi } from '@api/services/accountService/useGetOperationsApi';
import { DataWithState } from '@api/models/common';
import HistoryItem, { HistoryItemProps } from './historyItem/HistoryItem';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { useParamsAccountId } from '../Accounts/utils/useParamsAccountId';
import {
	getCreditEventOptions,
	getLoanEventOptions,
} from '../Accounts/utils/eventOptions';
import {
	CreditLineAccountV2Response,
	FinancingAccountV2Response,
} from '../../types/generated/pos';
import { getDateWithYear } from '@utils/dates/formatDates';
import { MappedAccountResponse } from '@api/mappers/accountMapper';
import { Operation } from '../../types/generated/operations';

const InnerContainer = styled.h3`
	font-size: 24px;
	font-weight: 800;
	line-height: 28px;
	padding-bottom: 32px;
	color: #1b1f3bcc;
	margin: 0;
`;

const CurrentDate = styled.div`
	font-size: 15px;
	line-height: 24px;
	font-weight: 800;
	margin: 12px 0;
`;

const getHistoryItems = (operations: Operation[]): HistoryItemProps[] => {
	return operations.map((operation): HistoryItemProps => {
		return {
			id: operation.identifier.id,
			direction: operation.direction,
			amount: operation.amount,
			iconCode: operation.brand.logo,
			providerName: operation.brand.name,
			timestamp: parseISO(operation.date),
			status: operation.status,
			type: operation.type,
			details: operation.details,
		};
	});
};

interface Props {
	accountsState: DataWithState<MappedAccountResponse>;
}

function PaymentHistory(props: Props) {
	const { accountsState } = props;
	const accountId = useParamsAccountId();

	const credit: CreditLineAccountV2Response | undefined = useMemo(() => {
		return (
			accountsState?.data?.accounts?.CREDIT_LINE?.find(
				(account) => account.id === String(accountId)
			) ?? undefined
		);
	}, [accountId, accountsState.data]);

	const loan: FinancingAccountV2Response = useMemo(() => {
		return accountsState.data?.accounts?.FINANCING?.find((currLoan) => {
			return accountId === Number(currLoan.id);
		}) as FinancingAccountV2Response;
	}, [accountId, accountsState.data]);

	const type = useMemo(() => {
		if (credit) return 'CREDIT_LINE';
		else if (loan) return 'FINANCING';
		else return;
	}, [credit, loan]);
	const operationsState = useGetOperationsApi({ type, accountId });

	const historyItems = useMemo(() => {
		return operationsState.data
			? getHistoryItems(operationsState.data.operations)
			: [];
	}, [operationsState.data?.operations]);

	useEffect(() => {
		if (loan) {
			const eventOptions = getLoanEventOptions(loan);
			track(AmplitudeEvents.PaymentHistory_Opened, { product: eventOptions });
		} else if (credit) {
			const eventOptions = getCreditEventOptions(credit);
			track(AmplitudeEvents.PaymentHistory_Opened, { product: eventOptions });
		}
	}, [loan, credit]);

	if (operationsState.loading) {
		return <LoadingScreen />;
	}

	if (!historyItems) {
		return (
			<section>
				<InnerContainer>Transactions</InnerContainer>
				<div>History will appear after the first payment</div>
			</section>
		);
	}

	let previousDate = '';

	return (
		<section>
			<InnerContainer>Transactions</InnerContainer>

			{historyItems.map((historyItem) => {
				const currentDate = getDateWithYear(historyItem.timestamp);

				if (currentDate === previousDate) {
					previousDate = currentDate;
					return <HistoryItem key={historyItem.id} {...historyItem} />;
				} else {
					previousDate = currentDate;
					return (
						<div key={historyItem.id}>
							<CurrentDate>{currentDate}</CurrentDate>
							<HistoryItem {...historyItem} />
						</div>
					);
				}
			})}
		</section>
	);
}

export default PaymentHistory;
