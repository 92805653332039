import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Divider } from '@fhl-world/salmon-ui';
import { useOrderApi } from '@api/services/loanService/loanApiService';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { DataWithState } from '@api/models/common';
import { LoanTypes } from '@api/models/account';
import { formatSentimo } from '@utils/formatSentimo/formatSentimo';
import { getLoanEventOptions } from '../Accounts/utils/eventOptions';
import { MappedAccountResponse } from '@api/mappers/accountMapper';
import { FinancingAccountV2Response } from '../../types/generated/pos';

const SubTitle = styled.div`
	color: rgba(27, 31, 59, 0.65);
	font-size: 17px;
	line-height: 28px;
	padding-top: 20px;
	font-weight: 800;
`;

const Caption = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 11px 0;
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
`;

const SubPrice = styled.div`
	color: rgba(27, 31, 59, 0.4);
	font-style: normal;
	font-size: 13px;
	font-weight: 500;
	line-height: 20px;
`;

const TotalContainer = styled(SubTitle)`
	padding-top: 9px;
`;

const TotalInnerContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Title = styled.h3`
	font-size: 24px;
	line-height: 28px;
	font-weight: 800;
	margin: 0;
	padding: 0;
`;

const DetailsContainer = styled.div`
	padding: 9px 0;
`;

interface DetailsProps {
	accountsState: DataWithState<MappedAccountResponse>;
}

const detailsConfig = {
	[LoanTypes.POS]: {
		title: 'Order details',
		labelAmount: 'Financed amount',
		showDownPayment: true,
		showAdditionalOrderInfo: true,
	},
	[LoanTypes.CASH]: {
		title: 'Loan details',
		labelAmount: 'Loan amount',
		showDownPayment: false,
		showAdditionalOrderInfo: false,
	},
};

function Details(props: DetailsProps) {
	const { loanId } = useParams();
	const { accountsState } = props;

	const { data: orderData, loading, error } = useOrderApi(loanId as string);

	const loan: FinancingAccountV2Response | undefined = useMemo(() => {
		return (
			accountsState.data?.accounts?.FINANCING?.find((currLoan) => {
				return currLoan.id.toString() === loanId;
			}) ?? undefined
		);
	}, [accountsState.data?.accounts?.FINANCING, loanId]);

	useEffect(() => {
		if (loan) {
			const eventOptions = getLoanEventOptions(loan);
			track(AmplitudeEvents.OrderDetails_Opened, { product: eventOptions });
		}
	}, []);

	if (!loan?.loanType) {
		return null;
	}

	if (loading) {
		return <LoadingScreen />;
	}

	if (error || !orderData) {
		return null;
	}

	const selectedTextConfig = detailsConfig[loan?.loanType];

	return (
		<div>
			<Title>{selectedTextConfig.title}</Title>

			<SubTitle>Terms</SubTitle>

			<DetailsContainer>
				<Caption>
					<div>{selectedTextConfig.labelAmount}</div>
					<div>{formatSentimo(orderData.loanTerms.loanAmount)}</div>
				</Caption>
				<Divider />

				{selectedTextConfig.showDownPayment && (
					<>
						<Caption>
							<div>Down payment</div>
							<div>{formatSentimo(orderData.loanTerms.downPayment)}</div>
						</Caption>
						<Divider />
					</>
				)}

				<Caption>
					<div>Monthly payment</div>
					<div>{formatSentimo(orderData.loanTerms.monthlyPayment)}</div>
				</Caption>
				<Divider />

				<Caption>
					<div>Installment period</div>
					<div>{orderData.loanTerms.loanTerms} months</div>
				</Caption>
				<Divider />
			</DetailsContainer>

			{selectedTextConfig.showAdditionalOrderInfo && (
				<>
					<SubTitle>Store</SubTitle>
					<Caption>{`${orderData.storeName}, ${orderData.storeAddress}`}</Caption>

					<SubTitle>Regular items</SubTitle>

					{orderData.items.map(({ price, quantity, name, total }) => {
						return (
							<div key={`${price}/${quantity}/${name}`}>
								<Caption>
									<div>
										<div>{name}</div>
										<SubPrice>
											{quantity}×{formatSentimo(price)}
										</SubPrice>
									</div>
									<div>{formatSentimo(total)}</div>
								</Caption>
								<Divider />
							</div>
						);
					})}

					<SubTitle>Basket items</SubTitle>
					<Caption>
						<div>Amount</div>
						<div>{formatSentimo(orderData.basket?.amount || 0)}</div>
					</Caption>
					<Divider />

					<TotalContainer>
						<TotalInnerContainer>
							<div>Total</div>
							<div>{formatSentimo(orderData.total)}</div>
						</TotalInnerContainer>
					</TotalContainer>
				</>
			)}
		</div>
	);
}

export default Details;
