import { Money, MoneyCurrencyEnum } from '../../types/generated/pos';
import { Money as OperationMoney } from '../../types/generated/operations';

const getPeso = (money: Money | OperationMoney | number): number => {
	const isNum = typeof money === 'number';

	if (isNum) {
		return money / 100;
	}

	return money.amount;
};

const getCurrency = (money: Money | OperationMoney | number): string => {
	const isNum = typeof money === 'number';
	return isNum
		? MoneyCurrencyEnum.PHP
		: money.currency || MoneyCurrencyEnum.PHP;
};

// {amount: 1234.1, currency: 'PHP'} => ₱1,234.10
export const formatMoney = (money: Money | OperationMoney | number): string => {
	const peso = getPeso(money);

	return peso.toLocaleString('en-US', {
		minimumFractionDigits: 2,
		style: 'currency',
		currency: getCurrency(money),
	});
};

// 1234 => ₱1,234.00
export const formatPeso = (amount: number): string => {
	return amount.toLocaleString('en-US', {
		minimumFractionDigits: 2,
		style: 'currency',
		currency: MoneyCurrencyEnum.PHP,
	});
};
