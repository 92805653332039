import { useFetch } from '@api/services/utilsService/utilsApiService';
import { OPERATIONS_URL } from '@utils/common';
import { OperationsResponse } from '../../../types/generated/operations';

type Params = {
	type?: 'CREDIT_LINE' | 'FINANCING';
	accountId: string | number;
};

export const useGetOperationsApi = (params: Params) => {
	const { type, accountId } = params;

	const url = new URL(`${OPERATIONS_URL}/v1/operations`);
	url.searchParams.append('type', type || '');
	url.searchParams.append('accountId', String(accountId));
	url.searchParams.append('page', '0');
	url.searchParams.append('size', '1000');

	return useFetch<OperationsResponse>(type ? url.toString() : null);
};
