import { useMemo } from 'react';

import CreditAccount from './CreditAccount';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { NotFound } from '../NotFound/NotFound';
import { useParamsAccountId } from './utils/useParamsAccountId';
import { DataWithState } from '@api/models/common';
import {
	CreditLineAccountV2Response,
	CreditLineStateResponse,
} from '../../types/generated/pos';
import { MappedAccountResponse } from '@api/mappers/accountMapper';

interface Props {
	accountsState: DataWithState<MappedAccountResponse>;
	creditStatusState: DataWithState<CreditLineStateResponse>;
}

function CreditAccountPage(props: Props) {
	const { accountsState, creditStatusState } = props;
	const accountId = useParamsAccountId();

	const credit: CreditLineAccountV2Response | undefined = useMemo(() => {
		return (
			accountsState.data?.accounts?.CREDIT_LINE?.find(
				(acc) => Number(acc?.id) === accountId
			) ?? undefined
		);
	}, [accountId, accountsState.data]);

	if (accountsState.loading || creditStatusState.loading) {
		return <LoadingScreen />;
	}

	if (
		accountsState.error ||
		creditStatusState.error ||
		!credit ||
		!creditStatusState.data
	) {
		return <NotFound />;
	}

	return (
		<CreditAccount credit={credit} creditStatus={creditStatusState.data} />
	);
}

export default CreditAccountPage;
