import {
	CreditLineAccountV2Response,
	FinancingAccountV2Response,
} from 'src/types/generated/pos';
import { AccountParams } from '../QrRepayment';

export const getAccountById = (
	params: AccountParams
): CreditLineAccountV2Response | FinancingAccountV2Response => {
	const { accountId, accounts } = params;
	const { FINANCING, CREDIT_LINE } = accounts;

	const account = [...CREDIT_LINE, ...FINANCING]
		.filter(Boolean)
		.find((accountItem) => {
			if (
				(accountItem as CreditLineAccountV2Response).id === String(accountId)
			) {
				return true;
			}
			return (
				(accountItem as FinancingAccountV2Response)?.id === String(accountId)
			);
		});

	if (!account) {
		throw new Error('Could not find account item by ID');
	}

	return account;
};
