import { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { track } from '@amplitude/analytics-browser';

import LoanPaymentInfo from './components/LoanPaymentInfo';
import LoanNotification from './components/LoanNotification';
import ClosedAccount from './components/ClosedAccount';
import LoanOverview from './components/LoanOverview/LoanOverview';
import PaymentForm, { PaymentFormValues } from './components/PaymentForm';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import { LoanTypeToCaption } from '../MainPage/AccountList/AccountList';
import { usePostPaymentApi } from '@api/services/accountService/usePostPaymentApi';
import { useSuccessRedirect } from './utils/useSuccessRedirect';
import {
	MoneyCurrencyEnum,
	RepaymentPaymentAccountAccountTypeEnum,
	FinancingAccountV2Response,
	FinancingAccountV2ResponseStatusEnum,
} from '../../types/generated/pos';
import { getLoanEventOptions } from './utils/eventOptions';
import { PaymentTitle } from './components/PaymentTitle';
import { Caption } from './components/Caption';
import { useTrackLoanEvents } from './utils/useTrackLoanEvents';

const PaymentContainer = styled.div`
	margin-top: 16px;
`;

const PlaceholderText = styled.p`
	font-size: 15px;
	line-height: 24px;
	font-weight: 500;
	font-style: normal;
	margin: 0 0 16px;
`;

interface Props {
	loan: FinancingAccountV2Response;
}

function LoanAccount(props: Props) {
	const { loan } = props;

	const eventOptions = getLoanEventOptions(loan);

	useTrackLoanEvents(loan);

	const loanStatus = loan?.state?.type;

	const [{ data: paymentData, error: paymentError, loading }, mutate] =
		usePostPaymentApi();

	const initialValues: Partial<PaymentFormValues> = useMemo(() => {
		return {
			amount: loan?.state?.amount?.amount ?? undefined,
			providerId: undefined,
		};
	}, [loan?.state]);

	const handleSubmit = useCallback(
		({ amount, providerId }: PaymentFormValues) => {
			if (!providerId) {
				return;
			}

			track(AmplitudeEvents.GoToPayment_Tap, { product: eventOptions });

			mutate({
				providerId,
				amount: { amount, currency: MoneyCurrencyEnum.PHP },
				from: {
					accountId: String(loan.id),
					accountType: RepaymentPaymentAccountAccountTypeEnum.FINANCING,
				},
			});
		},
		[track, eventOptions]
	);

	useSuccessRedirect(paymentData?.location);

	if (loan.status === FinancingAccountV2ResponseStatusEnum.CLOSED) {
		return <ClosedAccount loanType={loan.loanType} />;
	}

	return (
		<div>
			<Caption>{LoanTypeToCaption[loan.loanType] || ''}</Caption>

			{loanStatus && (
				<LoanPaymentInfo notificationState={loanStatus} loan={loan} />
			)}

			{loanStatus && <LoanNotification loan={loan} />}

			<PaymentContainer>
				<PaymentTitle>Payment</PaymentTitle>
				<PlaceholderText>
					Enter an amount and select a payment option
				</PlaceholderText>
			</PaymentContainer>

			<PaymentForm
				initialValues={initialValues}
				onSubmit={handleSubmit}
				eventOptions={eventOptions}
				serverError={paymentError}
				loading={loading}
			/>

			<LoanOverview loan={loan} />
		</div>
	);
}

export default LoanAccount;
