import { Route, Routes } from 'react-router-dom';
import styled from '@emotion/styled';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useFetch } from '@api/services/utilsService/utilsApiService';
import { UserData } from '@api/models/user';
import { BASE_URL } from '@utils/common';
import { useAccountApi } from '@api/services/accountService/accountApiService';
import { useGetCreditStatusApi } from '@api/services/creditService/useGetCreditStatusApi';

import MainAppBar from '../../components/MainAppBar/MainAppBar';
import MainPage from '../MainPage/MainPage';
import Profile from '../Profile/Profile';
import Details from '../Order/Details';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import PaymentHistory from '../PaymentHistory/PaymentHistory';
import PaymentSchedule from '../PaymentSchedule/PaymentSchedule';
import PaymentSuccess from '../PaymentSuccess/PaymentSuccess';
import PaymentFailed from '../PaymentFailed/PaymentSuccess';
import { NotFound } from '../NotFound/NotFound';
import BannerGetTheApp from '../BannerGetTheApp';
import CreditAccountPage from '../Accounts/CreditAccountPage';
import LoanAccountPage from '../Accounts/LoanAccountPage';
import QrRepaymentPage from '../QrRepayment/QrRepaymentPage';
import { accountMapper } from '@api/mappers/accountMapper';

const Container = styled.main`
	max-width: 380px;
	margin: auto;
	padding: 16px;
	color: rgba(27, 31, 59, 0.8);
`;

function RoutePage() {
	const userDataRes = useFetch<UserData>(`${BASE_URL}/v1/user/profile`);
	const userData = userDataRes.data;
	const { logout } = useAuth0();

	const accountsState = useAccountApi();
	const mappedAccountsState = accountMapper(accountsState);
	const creditStatusState = useGetCreditStatusApi();

	const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

	if (!userDataRes.loading && userData) {
		return (
			<div>
				<BannerGetTheApp />
				<MainAppBar userName={userData.fullName} />
				<Container>
					<SentryRoutes>
						<Route
							path={'/'}
							element={
								<MainPage
									accountsState={mappedAccountsState}
									creditStatusState={creditStatusState}
								/>
							}
						/>

						<Route
							path={'/financing/:accountId'}
							element={<LoanAccountPage accountsState={mappedAccountsState} />}
						/>
						<Route
							path={'/creditLine/:accountId'}
							element={
								<CreditAccountPage
									creditStatusState={creditStatusState}
									accountsState={mappedAccountsState}
								/>
							}
						/>
						<Route
							path={'/qrRepayment/:accountId'}
							element={
								<QrRepaymentPage
									accountsState={mappedAccountsState}
									userName={userData.fullName}
								/>
							}
						/>

						<Route
							path={'/profile'}
							element={<Profile profileData={userData} onLogout={logout} />}
						/>
						<Route
							path={'/order/:loanId'}
							element={<Details accountsState={mappedAccountsState} />}
						/>
						<Route
							path={'/history/:accountId'}
							element={<PaymentHistory accountsState={mappedAccountsState} />}
						/>
						<Route
							path={'/schedule/:loanId'}
							element={<PaymentSchedule accountsState={mappedAccountsState} />}
						/>
						<Route path={'/payment_success'} element={<PaymentSuccess />} />
						<Route path={'/payment_error'} element={<PaymentFailed />} />
						<Route path="*" element={<NotFound />} />
					</SentryRoutes>
				</Container>
			</div>
		);
	} else {
		return <LoadingScreen />;
	}
}

export default RoutePage;
