import { useNavigate } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';

import AccountOverview from '../AccountOverview';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import {
	FinancingAccountV2Response,
	FinancingAccountV2ResponseLoanTypeEnum,
} from '../../../../types/generated/pos';
import { getLoanEventOptions } from '../../utils/eventOptions';
import { useGetLoanAgreementApi } from '@api/services/accountService/useGetAgreementApi';
import { Alert, Snackbar } from '@fhl-world/salmon-ui';
import { useMemo, useState } from 'react';
import styled from '@emotion/styled';

interface Props {
	loan: FinancingAccountV2Response;
}

const ErrorNotification = styled(Alert)`
	border-radius: 12px;
`;

function LoanOverview({ loan }: Props) {
	const { id, progress, loanType, coreBanking } = loan;

	const [isAlertShown, setShowAlert] = useState(false);
	const [isAgreementButtonDisabled, setAgreementButtonDisabled] =
		useState(false);

	const navigate = useNavigate();
	const agreement = useGetLoanAgreementApi(id, coreBanking);

	const errorAgreementMessage =
		typeof agreement.error === 'string'
			? agreement.error
			: agreement?.error?.message || 'Something went wrong';

	const getAgreementLink = (): string | undefined => {
		if (agreement.data instanceof Blob) {
			return URL.createObjectURL(agreement.data);
		}

		if (agreement.data && 'location' in agreement.data) {
			return agreement.data.location;
		}

		return undefined;
	};

	const agreementLink = getAgreementLink();
	const eventOptions = getLoanEventOptions(loan);

	const options = useMemo(() => {
		return [
			{
				label: 'Completed payments',
				text: `${progress.paid} of ${progress.total}`,
			},
			{
				label: `Payment schedule`,
				onClick: () => {
					track(AmplitudeEvents.PaymentSchedule_Tap, { product: eventOptions });
					navigate(`/schedule/${id}`);
				},
			},
			{
				label: `Transaction history`,
				onClick: () => {
					track(AmplitudeEvents.PaymentHistory_Tap, { product: eventOptions });
					navigate(`/history/${id}`);
				},
			},
			{
				label: `${
					loanType === FinancingAccountV2ResponseLoanTypeEnum.POS
						? 'Order'
						: 'Loan'
				} details`,
				onClick: () => {
					track(AmplitudeEvents.Order_Tap, { product: eventOptions });
					navigate(`/order/${id}`);
				},
			},
			{
				label: 'Signed contract',
				isDisabled: isAgreementButtonDisabled || agreement.loading,
				onClick: () => {
					if (!agreementLink) {
						setShowAlert(true);
						setAgreementButtonDisabled(true);
						return;
					}
					track(AmplitudeEvents.Agreement_Tap, {
						product: eventOptions,
						source:
							loan.loanType === FinancingAccountV2ResponseLoanTypeEnum.CASH
								? 'CashloanMain'
								: 'POSMain',
					});
					window.open(agreementLink, '_blank');
				},
			},
		];
	}, [agreement, isAgreementButtonDisabled]);

	return (
		<>
			<Snackbar
				open={isAlertShown}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				onClose={() => setShowAlert(false)}
				autoHideDuration={5000}
			>
				<ErrorNotification severity="error" onClose={() => setShowAlert(false)}>
					Oops! {errorAgreementMessage} <br />
					Please try again later.
				</ErrorNotification>
			</Snackbar>
			<AccountOverview options={options} />
		</>
	);
}

export default LoanOverview;
