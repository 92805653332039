import { useMemo } from 'react';

import LoanAccount from './LoanAccount';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { NotFound } from '../NotFound/NotFound';
import { useParamsAccountId } from './utils/useParamsAccountId';
import { DataWithState } from '@api/models/common';
import { FinancingAccountV2Response } from '../../types/generated/pos';
import { MappedAccountResponse } from '@api/mappers/accountMapper';

interface Props {
	accountsState: DataWithState<MappedAccountResponse>;
}

function LoanAccountPage(props: Props) {
	const { accountsState } = props;
	const accountId = useParamsAccountId();

	const loan: FinancingAccountV2Response = useMemo(() => {
		return accountsState.data?.accounts?.FINANCING?.find((currLoan) => {
			return accountId === Number(currLoan?.id);
		}) as FinancingAccountV2Response;
	}, [accountId, accountsState.data]);

	if (accountsState.loading) {
		return <LoadingScreen />;
	}

	if (!loan) {
		return <NotFound />;
	}

	return <LoanAccount loan={loan} />;
}

export default LoanAccountPage;
