import { useNavigate } from 'react-router-dom';
import { track } from '@amplitude/analytics-browser';

import AccountOverview from '../AccountOverview';
import { AmplitudeEvents } from '@utils/amplitudeEvents';
import {
	FinancingAccountV2Response,
	FinancingAccountV2ResponseLoanTypeEnum,
} from '../../../../types/generated/pos';
import { getLoanEventOptions } from '../../utils/eventOptions';
import { useGetLoanAgreementApi } from '@api/services/accountService/useGetAgreementApi';

interface Props {
	loan: FinancingAccountV2Response;
}

function LoanOverview({ loan }: Props) {
	const { id, progress, loanType, coreBanking } = loan;
	const navigate = useNavigate();

	const agreement = useGetLoanAgreementApi(id, coreBanking);

	const getAgreementLink = Array.isArray(agreement.data)
		? agreement.data?.at(0)
		: agreement.data?.location;

	const eventOptions = getLoanEventOptions(loan);

	const options = [
		{
			label: 'Completed payments',
			text: `${progress.paid} of ${progress.total}`,
		},

		{
			label: `Payment schedule`,
			onClick: () => {
				track(AmplitudeEvents.PaymentSchedule_Tap, { product: eventOptions });
				navigate(`/schedule/${id}`);
			},
		},

		{
			label: `Transaction history`,
			onClick: () => {
				track(AmplitudeEvents.PaymentHistory_Tap, { product: eventOptions });
				navigate(`/history/${id}`);
			},
		},

		{
			label: `${
				loanType === FinancingAccountV2ResponseLoanTypeEnum.POS
					? 'Order'
					: 'Loan'
			} details`,
			onClick: () => {
				track(AmplitudeEvents.Order_Tap, { product: eventOptions });
				navigate(`/order/${id}`);
			},
		},
		{
			label: 'Signed contract',
			onClick: () => {
				if (!getAgreementLink) {
					return;
				}
				track(AmplitudeEvents.Agreement_Tap, {
					product: eventOptions,
					source:
						loan.loanType === FinancingAccountV2ResponseLoanTypeEnum.CASH
							? 'CashloanMain'
							: 'POSMain',
				});
				window.open(getAgreementLink, '_blank');
			},
		},
	];

	return <AccountOverview options={options} />;
}

export default LoanOverview;
