/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AccountType {
	CREDIT_LINE = 'CREDIT_LINE',
	FINANCING = 'FINANCING',
	DEBIT = 'DEBIT',
	SAVINGS = 'SAVINGS',
}

export interface BankTransfer {
	transferToAccountId?: string;
	transferToAccountType?: AccountType;
	type: BankTransferTypeEnum;
}

export enum BankTransferTypeEnum {
	BANK_TRANSFER = 'BANK_TRANSFER',
}

export interface CardDetails {
	cardLastFour: string;
	merchantType?: string;
	type: CardDetailsTypeEnum;
}

export enum CardDetailsTypeEnum {
	CARD_DETAILS = 'CARD_DETAILS',
}

export enum Currency {
	PHP = 'PHP',
}

export enum ErrorTypeEnum {
	OperationsapiOperationExceptionCantBuildOperation = 'operationsapi.OperationException.CantBuildOperation',
}

export interface ExportOperationsRequest {
	/**
	 * @format local-date
	 * @example "2023-04-26"
	 */
	fromDate: string;
	/**
	 * @format local-date
	 * @example "2023-04-26"
	 */
	toDate: string;
}

export enum FinancialInstitution {
	SUNPRIME = 'SUNPRIME',
	FHL_FINANCING = 'FHL_FINANCING',
}

export interface InternalOperation {
	authorizationWithTransactions?: {
		authorization?: object;
		transactions?: object[];
	}[];
	/** @format date-time */
	eventTime?: string;
	/** https://gitlab.com/fhl-world/payment-gate/payment-gate-core/blob/c98158b2e3859e05b217811e79a4ae79f7d41545/src/main/kotlin/org/fhl/world/payment/gate/integration/kafka/dto/PaymentHistoryMessage.kt#L14-14 */
	paymentHistoryMessage?: object;
}

export type InternalOperationResponse = InternalOperation[];

export enum JobNameEnum {
	ExportAuthorizationsJob = 'exportAuthorizationsJob',
	ExportTransactionsJob = 'exportTransactionsJob',
}

export type JobsCreateData = string;

export interface JobsCreateParams {
	jobName: JobNameEnum;
}

export enum JobsCreateParams1JobNameEnum {
	ExportAuthorizationsJob = 'exportAuthorizationsJob',
	ExportTransactionsJob = 'exportTransactionsJob',
}

export interface JobsDetailData {
	/** @example "2023-04-26T07:44:47.449497110Z" */
	completedAt?: string;
	/** @example "2023-04-26T07:44:47.449497110Z" */
	createdAt?: string;
	/** @format uuid */
	id?: string;
	name?: string;
	status?: StatusEnum;
}

export interface Money {
	/** @example 1500.75 */
	amount: number;
	currency: Currency;
}

export interface Operation {
	account: OperationAccount;
	allowDispute?: boolean;
	amount: Money;
	brand: OperationBrand;
	/** @format date */
	date: string;
	details?: OperationDetails;
	direction?: OperationDirection;
	fee?: Money;
	financialInstitution?: FinancialInstitution;
	identifier: OperationIdentifier;
	status: OperationStatus;
	/** @example "Kristine dela Cruz" */
	to: string;
	total: Money;
	type: OperationType;
}

export interface OperationAccount {
	accountId?: string;
	accountType: AccountType;
}

export interface OperationBrand {
	/** @example "https://s.slmn.ph/payment/providers/gcash.png" */
	logo: string;
	name: string;
}

export type OperationDetails =
	| OperationDetailsRepayment
	| OperationDetailsTransfer
	| OperationDetailsQrPayment
	| BankTransfer
	| CardDetails;

export interface OperationDetailsQrPayment {
	channelName: string;
	merchant: string;
	/** @example "Instapay" */
	transferServiceName: string;
	/** @example 123456 */
	transferServiceReferenceNumber?: string;
	type: OperationDetailsQrPaymentTypeEnum;
}

export enum OperationDetailsQrPaymentTypeEnum {
	QR_PAYMENT = 'QR_PAYMENT',
}

export interface OperationDetailsRepayment {
	failedReason?: OperationDetailsRepaymentFailedReasonEnum;
	provider: OperationProvider;
	type: OperationDetailsRepaymentTypeEnum;
}

export enum OperationDetailsRepaymentFailedReasonEnum {
	ACCOUNT_ACCESS_BLOCKED = 'ACCOUNT_ACCESS_BLOCKED',
	INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
	MAX_AMOUNT_LIMIT_ERROR = 'MAX_AMOUNT_LIMIT_ERROR',
	OTHERS = 'OTHERS',
}

export enum OperationDetailsRepaymentTypeEnum {
	REPAYMENT = 'REPAYMENT',
}

export interface OperationDetailsTransfer {
	accountName: string;
	accountNumber: string;
	provider: OperationProvider;
	/** @example "Instapay" */
	transferServiceName?: string;
	/** @example 123456 */
	transferServiceReferenceNumber?: string;
	type: OperationDetailsTransferTypeEnum;
}

export enum OperationDetailsTransferTypeEnum {
	TRANSFER = 'TRANSFER',
}

export enum OperationDirection {
	IN = 'IN',
	OUT = 'OUT',
}

export interface OperationIdentifier {
	id: string;
	system: System;
}

export interface OperationProvider {
	code: string;
	fee?: Money;
	id: string;
	isAutoPayment?: boolean;
	linkedPaymentMethod?: string;
	name: string;
}

export interface OperationResponse {
	operation: Operation;
}

export enum OperationStatus {
	SUCCESSFUL = 'SUCCESSFUL',
	PENDING = 'PENDING',
	FAILED = 'FAILED',
}

export enum OperationType {
	REPAYMENT = 'REPAYMENT',
	CASH_IN = 'CASH_IN',
	TRANSFER = 'TRANSFER',
	BANK_TRANSFER = 'BANK_TRANSFER',
	QR_PAYMENT = 'QR_PAYMENT',
	CARD_CASH_IN = 'CARD_CASH_IN',
	CARD_PAYMENT = 'CARD_PAYMENT',
	ATM_WITHDRAWAL = 'ATM_WITHDRAWAL',
	INTEREST_CHARGE = 'INTEREST_CHARGE',
	REWARD = 'REWARD',
	CORRECTION = 'CORRECTION',
	REFUND = 'REFUND',
	OTHERS = 'OTHERS',
}

export type OperationsDetailData = OperationResponse;

export interface OperationsDetailParams {
	operationId: string;
	system: System;
}

export type OperationsLastListData = OperationResponse;

export interface OperationsLastListParams {
	accountId?: string;
	accountType?: AccountType;
}

export type OperationsListData = OperationsResponse;

export interface OperationsListParams {
	accountId?: string;
	accountType?: AccountType;
	/**
	 * @format int32
	 * @example 0
	 */
	page: number;
	/**
	 * @format int32
	 * @example 50
	 */
	size: number;
	/**
	 * @format date-time
	 * @example "2023-09-11T22:48:34.547605Z"
	 */
	version?: string;
}

export interface OperationsResponse {
	operations: Operation[];
}

export enum StatusEnum {
	CREATED = 'CREATED',
	COMPLETED = 'COMPLETED',
	FAILED = 'FAILED',
}

export enum System {
	PG = 'PG',
	IF = 'IF',
}

export type V1OperationsListData = InternalOperationResponse;

export interface V1OperationsListParams {
	accountId?: string;
	accountType?: AccountType;
	/**
	 * @format int32
	 * @example 0
	 */
	page: number;
	/**
	 * @format int32
	 * @example 50
	 */
	size: number;
	/**
	 * @format date-time
	 * @example "2023-09-11T22:48:34.547605Z"
	 */
	version?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseFormat;
	/** request body */
	body?: unknown;
	/** base url */
	baseUrl?: string;
	/** request cancellation token */
	cancelToken?: CancelToken;
}

export type RequestParams = Omit<
	FullRequestParams,
	'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
	baseUrl?: string;
	baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
	securityWorker?: (
		securityData: SecurityDataType | null
	) => Promise<RequestParams | void> | RequestParams | void;
	customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
	extends Response {
	data: D;
	error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
	public baseUrl: string = 'https://operations-api.loc.uat.fhl.world';
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private abortControllers = new Map<CancelToken, AbortController>();
	private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
		fetch(...fetchParams);

	private baseApiParams: RequestParams = {
		credentials: 'same-origin',
		headers: {},
		redirect: 'follow',
		referrerPolicy: 'no-referrer',
	};

	constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
		Object.assign(this, apiConfig);
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected encodeQueryParam(key: string, value: any) {
		const encodedKey = encodeURIComponent(key);
		return `${encodedKey}=${encodeURIComponent(
			typeof value === 'number' ? value : `${value}`
		)}`;
	}

	protected addQueryParam(query: QueryParamsType, key: string) {
		return this.encodeQueryParam(key, query[key]);
	}

	protected addArrayQueryParam(query: QueryParamsType, key: string) {
		const value = query[key];
		return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
	}

	protected toQueryString(rawQuery?: QueryParamsType): string {
		const query = rawQuery || {};
		const keys = Object.keys(query).filter(
			(key) => 'undefined' !== typeof query[key]
		);
		return keys
			.map((key) =>
				Array.isArray(query[key])
					? this.addArrayQueryParam(query, key)
					: this.addQueryParam(query, key)
			)
			.join('&');
	}

	protected addQueryParams(rawQuery?: QueryParamsType): string {
		const queryString = this.toQueryString(rawQuery);
		return queryString ? `?${queryString}` : '';
	}

	private contentFormatters: Record<ContentType, (input: any) => any> = {
		[ContentType.Json]: (input: any) =>
			input !== null && (typeof input === 'object' || typeof input === 'string')
				? JSON.stringify(input)
				: input,
		[ContentType.Text]: (input: any) =>
			input !== null && typeof input !== 'string'
				? JSON.stringify(input)
				: input,
		[ContentType.FormData]: (input: any) =>
			Object.keys(input || {}).reduce((formData, key) => {
				const property = input[key];
				formData.append(
					key,
					property instanceof Blob
						? property
						: typeof property === 'object' && property !== null
						? JSON.stringify(property)
						: `${property}`
				);
				return formData;
			}, new FormData()),
		[ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
	};

	protected mergeRequestParams(
		params1: RequestParams,
		params2?: RequestParams
	): RequestParams {
		return {
			...this.baseApiParams,
			...params1,
			...(params2 || {}),
			headers: {
				...(this.baseApiParams.headers || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected createAbortSignal = (
		cancelToken: CancelToken
	): AbortSignal | undefined => {
		if (this.abortControllers.has(cancelToken)) {
			const abortController = this.abortControllers.get(cancelToken);
			if (abortController) {
				return abortController.signal;
			}
			return void 0;
		}

		const abortController = new AbortController();
		this.abortControllers.set(cancelToken, abortController);
		return abortController.signal;
	};

	public abortRequest = (cancelToken: CancelToken) => {
		const abortController = this.abortControllers.get(cancelToken);

		if (abortController) {
			abortController.abort();
			this.abortControllers.delete(cancelToken);
		}
	};

	public request = async <T = any, E = any>({
		body,
		secure,
		path,
		type,
		query,
		format,
		baseUrl,
		cancelToken,
		...params
	}: FullRequestParams): Promise<HttpResponse<T, E>> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const queryString = query && this.toQueryString(query);
		const payloadFormatter = this.contentFormatters[type || ContentType.Json];
		const responseFormat = format || requestParams.format;

		return this.customFetch(
			`${baseUrl || this.baseUrl || ''}${path}${
				queryString ? `?${queryString}` : ''
			}`,
			{
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type && type !== ContentType.FormData
						? { 'Content-Type': type }
						: {}),
				},
				signal:
					(cancelToken
						? this.createAbortSignal(cancelToken)
						: requestParams.signal) || null,
				body:
					typeof body === 'undefined' || body === null
						? null
						: payloadFormatter(body),
			}
		).then(async (response) => {
			const r = response as HttpResponse<T, E>;
			r.data = null as unknown as T;
			r.error = null as unknown as E;

			const data = !responseFormat
				? r
				: await response[responseFormat]()
						.then((data) => {
							if (r.ok) {
								r.data = data;
							} else {
								r.error = data;
							}
							return r;
						})
						.catch((e) => {
							r.error = e;
							return r;
						});

			if (cancelToken) {
				this.abortControllers.delete(cancelToken);
			}

			if (!response.ok) throw data;
			return data;
		});
	};
}

/**
 * @title API
 * @version 1.0.0
 * @baseUrl https://operations-api.loc.uat.fhl.world
 */
export class Api<
	SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
	v1 = {
		/**
		 * No description
		 *
		 * @tags operations
		 * @name OperationsList
		 * @request GET:/v1/operations
		 */
		operationsList: (query: OperationsListParams, params: RequestParams = {}) =>
			this.request<OperationsListData, any>({
				path: `/v1/operations`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags operations
		 * @name OperationsDetail
		 * @request GET:/v1/operations/{operationId}
		 */
		operationsDetail: (
			{ operationId, ...query }: OperationsDetailParams,
			params: RequestParams = {}
		) =>
			this.request<OperationsDetailData, any>({
				path: `/v1/operations/${operationId}`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags operations
		 * @name OperationsLastList
		 * @request GET:/v1/operations/last
		 */
		operationsLastList: (
			query: OperationsLastListParams,
			params: RequestParams = {}
		) =>
			this.request<
				OperationsLastListData,
				{
					errorDetails?: object;
					errorType?: ErrorTypeEnum;
					/** @example "Can't build operation response" */
					message?: string;
				}
			>({
				path: `/v1/operations/last`,
				method: 'GET',
				query: query,
				...params,
			}),
	};
	internal = {
		/**
		 * No description
		 *
		 * @tags internal
		 * @name V1OperationsList
		 * @request GET:/internal/v1/operations
		 */
		v1OperationsList: (
			query: V1OperationsListParams,
			params: RequestParams = {}
		) =>
			this.request<V1OperationsListData, any>({
				path: `/internal/v1/operations`,
				method: 'GET',
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags job
		 * @name JobsCreate
		 * @summary Starts a jobs
		 * @request POST:/internal/jobs
		 */
		jobsCreate: (
			query: JobsCreateParams,
			data: ExportOperationsRequest,
			params: RequestParams = {}
		) =>
			this.request<JobsCreateData, any>({
				path: `/internal/jobs`,
				method: 'POST',
				query: query,
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags job
		 * @name JobsDetail
		 * @summary Get a job
		 * @request GET:/internal/jobs/{jobId}
		 */
		jobsDetail: (jobId: string, params: RequestParams = {}) =>
			this.request<JobsDetailData, any>({
				path: `/internal/jobs/${jobId}`,
				method: 'GET',
				...params,
			}),
	};
}
