import { useEffect, useMemo, useRef } from 'react';
import styled from '@emotion/styled';

import QrImage from './components/QrImage';
import AmountTitle from './components/AmountTitle';
import Caption from './components/Caption/Caption';
import HowToPayHeader from './components/HowToPayHeader';
import HowToPayList from './components/HowToPayList';
import DownloadButton from './components/DownloadButton/DownloadButton';
import CaptionLoad from './components/Caption/CaptionLoad';
import { useParamsAccountId } from '../Accounts/utils/useParamsAccountId';
import { useGetQrRepayment } from '@api/services/repayments/getQrRepayment';
import { getAccountName } from './utils/getAccountName';
import { getAccountType } from './utils/getAccountType';
import { trackOpen } from './utils/analytics';
import type { Money, QRRepayment } from 'src/types/generated/pos';
import { MappedAccountObject } from '@api/mappers/accountMapper';

const Container = styled.div`
	padding: 0 16px;
	position: relative;
`;

interface QrRepaymentProps {
	accounts: MappedAccountObject;
	amount: Money;
	providerId: string;
	userName: string;
}

export type AccountParams = {
	accountId: number;
	accounts: MappedAccountObject;
};

const QrRepayment = (props: QrRepaymentProps) => {
	const { accounts, amount, providerId, userName } = props;
	const accountId = useParamsAccountId();

	const ref = useRef<HTMLDivElement>(null);

	const productName = useMemo(() => {
		return getAccountName({ accountId, accounts });
	}, [accounts, accountId]);

	useEffect(() => {
		trackOpen({ amount, accountId, accounts });
	}, []);

	const requestParams = useMemo(() => {
		const accountType = getAccountType({ accountId, accounts });
		return {
			amount,
			providerId,
			from: { accountId: String(accountId), accountType },
		};
	}, [amount, providerId, accountId, accounts]);

	const { data, error } = useGetQrRepayment(requestParams);
	useEffect(() => {
		if (error) throw new Error(`Failed to fetch QR repayment`);
	}, [error]);
	const qrPaymentData = data?.paymentData as QRRepayment | undefined;

	return (
		<Container>
			<div ref={ref}>
				<QrImage src={qrPaymentData?.qrUrl} />

				<AmountTitle amount={amount} />

				{productName ? (
					<Caption
						userName={userName}
						productName={productName}
						isLoading={!data}
						expirationDate={qrPaymentData?.expirationTime}
					/>
				) : (
					<CaptionLoad />
				)}

				<HowToPayHeader />
				<HowToPayList />
			</div>

			<DownloadButton isLoading={!data} parentRef={ref} />
		</Container>
	);
};

export default QrRepayment;
