import { getAccountById } from './getAccountById';
import {
	FinancingAccountV2Response,
	RepaymentPaymentAccountAccountTypeEnum,
} from 'src/types/generated/pos';

export const getAccountType = (
	params: Parameters<typeof getAccountById>[0]
): RepaymentPaymentAccountAccountTypeEnum => {
	const account = getAccountById(params);

	return (account as FinancingAccountV2Response).loanType
		? RepaymentPaymentAccountAccountTypeEnum.FINANCING
		: RepaymentPaymentAccountAccountTypeEnum.CREDIT_LINE;
};
