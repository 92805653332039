import { differenceInCalendarDays } from 'date-fns';

import Notification from './Notification/Notification';
import { parseDate } from '@utils/date';
import { PermittedNotificationTypes } from './Notification/config';
import {
	FinancingAccountV2Response,
	MissedFeeTypeEnum,
	MissedFeeUpcoming,
	MissedFeeUpcomingTypeEnum,
	MissedPaymentAndFee,
	MissedPaymentAndFeeTypeEnum,
	MissedPaymentAndFeeUpcoming,
	MissedPaymentAndFeeUpcomingTypeEnum,
	MissedPaymentTypeEnum,
	NewTypeEnum,
	NoMissedPaymentsTypeEnum,
	PaidFullTypeEnum,
	PaidMore,
	PaidMoreTypeEnum,
	PartialPaid,
	PartialPaidTypeEnum,
	TodayPartialPaid,
	TodayPartialPaidTypeEnum,
	TodayTypeEnum,
	UpcomingNotPaid,
	UpcomingNotPaidTypeEnum,
	UpcomingPartialPaid,
	UpcomingPartialPaidTypeEnum,
} from '../../../types/generated/pos';
import { getDateWithYear } from '@utils/dates/formatDates';
import { formatMoney } from '@utils/formatMoney/formatMoney';

interface PaymentNotificationProps {
	loan: FinancingAccountV2Response;
}

function LoanNotification(props: PaymentNotificationProps) {
	const { loan } = props;
	const { state, progress } = loan;

	const parsedDueDate = state?.dueDate ? parseDate(state.dueDate) : null;

	if (props.loan) {
		switch (props.loan.state.type) {
			case MissedPaymentAndFeeTypeEnum.MissedPaymentAndFee: {
				const paymentInfo = state as MissedPaymentAndFee;
				return (
					<Notification
						type={PermittedNotificationTypes.BAD_NOTIFICATION}
						msgCmp={
							<div>
								You missed {progress.missed} payments. Remains payment is{' '}
								{formatMoney(paymentInfo?.debt)}, plus included a late fee of{' '}
								{formatMoney(paymentInfo?.fee)}. Pay now to avoid more late fees
							</div>
						}
					/>
				);
			}
			case MissedPaymentTypeEnum.MissedPayment: {
				return (
					<Notification
						type={PermittedNotificationTypes.BAD_NOTIFICATION}
						msgCmp={
							<div>
								You missed {progress.missed} payment. Pay now to avoid late fees
							</div>
						}
					/>
				);
			}
			case PaidMoreTypeEnum.PaidMore: {
				const paymentInfo = state as PaidMore;
				return (
					<Notification
						type={PermittedNotificationTypes.SUCCESS_NOTIFICATION}
						msgCmp={
							<div>
								Payment received. You overpaid{' '}
								{formatMoney(paymentInfo?.overpaid)}, which will be applied to
								your next payment. The next payment will be{' '}
								{formatMoney(state?.amount)} due on{' '}
								{parsedDueDate && getDateWithYear(parsedDueDate)}
							</div>
						}
					/>
				);
			}
			case NewTypeEnum.New: {
				return (
					<Notification
						type={PermittedNotificationTypes.SUCCESS_NOTIFICATION}
						msgCmp={
							"Congratulations on your new purchase! You'll be notified about the first payment 7 days before"
						}
					/>
				);
			}
			case NoMissedPaymentsTypeEnum.NoMissedPayments: {
				return (
					<Notification
						type={PermittedNotificationTypes.SUCCESS_NOTIFICATION}
						msgCmp={
							"No missed payments. You'll be notified about upcoming payment 7 days before"
						}
					/>
				);
			}
			case PartialPaidTypeEnum.PartialPaid: {
				const paymentInfo = state as PartialPaid;
				return (
					<Notification
						type={PermittedNotificationTypes.SUCCESS_NOTIFICATION}
						msgCmp={`You made a partial payment of ${formatMoney(
							paymentInfo?.paid
						)} out of ${formatMoney(paymentInfo?.base)} `}
					/>
				);
			}
			case TodayPartialPaidTypeEnum.TodayPartialPaid: {
				const paymentInfo = state as TodayPartialPaid;
				return (
					<Notification
						type={PermittedNotificationTypes.WARNING_NOTIFICATION}
						msgCmp={
							<div>
								Today is your due date. You paid{' '}
								{formatMoney(paymentInfo?.paid)} out of{' '}
								{formatMoney(paymentInfo?.base)}. Don’t forget to pay in full to
								avoid late fees
							</div>
						}
					/>
				);
			}
			case TodayTypeEnum.Today:
				return (
					<Notification
						type={PermittedNotificationTypes.WARNING_NOTIFICATION}
						msgCmp={
							<div>
								<div>Today is your due date.</div>
								<div>Don’t forget to pay in full to avoid late fees</div>
							</div>
						}
					/>
				);
			case UpcomingPartialPaidTypeEnum.UpcomingPartialPaid: {
				const paymentInfo = state as UpcomingPartialPaid;
				return (
					<Notification
						type={PermittedNotificationTypes.WARNING_NOTIFICATION}
						msgCmp={
							<div>
								{differenceInCalendarDays(
									paymentInfo.daysBeforeDueDate,
									new Date()
								)}{' '}
								days to the due date. You made a partial payment of{' '}
								{formatMoney(paymentInfo?.paid)} out of{' '}
								{formatMoney(paymentInfo?.base)}. Don’t forget to pay in full to
								avoid late fees
							</div>
						}
					/>
				);
			}
			case UpcomingNotPaidTypeEnum.UpcomingNotPaid: {
				const paymentInfo = state as UpcomingNotPaid;
				return (
					<Notification
						type={PermittedNotificationTypes.WARNING_NOTIFICATION}
						msgCmp={
							<div>
								{differenceInCalendarDays(
									paymentInfo.daysBeforeDueDate,
									new Date()
								)}{' '}
								days to the due date. Don’t forget to pay to avoid late fees
							</div>
						}
					/>
				);
			}
			case PaidFullTypeEnum.PaidFull: {
				return (
					<Notification
						type={PermittedNotificationTypes.SUCCESS_NOTIFICATION}
						msgCmp={
							<div>
								Payment received. The next payment will be{' '}
								{formatMoney(state?.amount)} due on{' '}
								{parsedDueDate && getDateWithYear(parsedDueDate)}
							</div>
						}
					/>
				);
			}
			case MissedPaymentAndFeeUpcomingTypeEnum.MissedPaymentAndFeeUpcoming: {
				const paymentInfo = state as MissedPaymentAndFeeUpcoming;
				return (
					<Notification
						type={PermittedNotificationTypes.BAD_NOTIFICATION}
						msgCmp={
							<div>
								You missed {progress.missed} payment. You need to pay{' '}
								{formatMoney(paymentInfo?.debt)}, plus a late fee of{' '}
								{formatMoney(paymentInfo?.fee)}. Reminding you that the next
								payment of {formatMoney(paymentInfo?.upcoming)} is still due on{' '}
								{parseDate(paymentInfo.upcomingDueDate) &&
									getDateWithYear(parseDate(paymentInfo.upcomingDueDate))}
								. Pay now to avoid more late fees
							</div>
						}
					/>
				);
			}
			case MissedFeeTypeEnum.MissedPayment:
				return (
					<Notification
						type={PermittedNotificationTypes.BAD_NOTIFICATION}
						msgCmp={
							<div>
								You still need to pay a late fee of {formatMoney(state?.amount)}
								. Pay now to avoid more late fees
							</div>
						}
					/>
				);
			case MissedFeeUpcomingTypeEnum.MissedFeeUpcoming: {
				const paymentInfo = state as MissedFeeUpcoming;
				return (
					<Notification
						type={PermittedNotificationTypes.BAD_NOTIFICATION}
						msgCmp={
							<div>
								You still need to pay a late fee of{' '}
								{formatMoney(paymentInfo?.amount)}. Reminding you that the next
								payment of {formatMoney(paymentInfo?.upcoming)} is still due on{' '}
								{parseDate(paymentInfo.upcomingDueDate) &&
									getDateWithYear(parseDate(paymentInfo.upcomingDueDate))}
								. Pay now to avoid more late fees
							</div>
						}
					/>
				);
			}
			default:
				return null;
		}
	}

	return <div></div>;
}

export default LoanNotification;
